import { Fragment, h } from "preact";
import { observer } from "mobx-react";
import { t, translate } from "../I18n";
import DeliveryFormItemWrapper from "./DeliveryFormItemWrapper";
import DeliveryAddressDivider from "./DeliveryAddressDivider";
import TextField from "./TextField";
function StreetAddressForm(props) {
    const { fieldId, controller, onChange, onBlur } = props;
    const { fields, isPostCodeRequired } = controller;
    const fieldIds = {
        street: `${fieldId}.street`,
        streetNumber: `${fieldId}.streetNumber`,
        city: `${fieldId}.city`,
        postCode: `${fieldId}.postCode`,
    };
    return (h(Fragment, null,
        h(DeliveryFormItemWrapper, { name: "street" },
            h(TextField, { name: fieldIds.street, dataFieldId: fieldIds.street, dataTestId: "deliveryAddress/street", label: translate(t.activerecord.attributes.delivery_address.street), controller: fields.street, required: true, autocomplete: "shipping address-line1", onChange: onChange, onBlur: onChange })),
        h(DeliveryFormItemWrapper, { name: "street-number" },
            h(TextField, { name: fieldIds.streetNumber, dataFieldId: fieldIds.streetNumber, dataTestId: "deliveryAddress/streetNumber", label: translate(t.activerecord.attributes.delivery_address.street_number), controller: fields.streetNumber, required: true, autocomplete: "shipping address-line2", onChange: onChange, onBlur: onBlur })),
        h(DeliveryAddressDivider, null),
        h(DeliveryFormItemWrapper, { name: "city" },
            h(TextField, { name: fieldIds.city, dataFieldId: fieldIds.city, dataTestId: "deliveryAddress/city", label: translate(t.activerecord.attributes.delivery_address.city), controller: fields.city, required: true, autocomplete: "shipping address-level2", onChange: onChange, onBlur: onBlur })),
        isPostCodeRequired && (h(DeliveryFormItemWrapper, { name: "post-code" },
            h(TextField, { name: fieldIds.postCode, dataFieldId: fieldIds.postCode, label: translate(t.activerecord.attributes.delivery_address.post_code), controller: fields.postCode, required: true, autocomplete: "shipping postal-code", onChange: onChange, onBlur: onBlur })))));
}
export default observer(StreetAddressForm);
